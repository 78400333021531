import React, { Component } from 'react'
import { graphql } from 'gatsby'
import {
    Nav,
    Row, Col,
    Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next } from '@sckit/gatsby-plugin-i18next'

import { book, bookurl, messages } from '../../../util/book.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faHome
 } from '@fortawesome/free-solid-svg-icons'

import Layout from '../../../components/layout'
import LocalImage from '../../../components/local-image';

import '../../../css/home.css'

class ARWS2BookIndexPage extends Component {
    render() {

        const bookName = "arwS2"

        return (
            <NamespacesConsumer>
                {(t, { i18n }) => {
                    // console.log(i18n)
                    const lang = i18n.language;
                    const path = "/"+lang
                    // console.log( 'language : ' + lang)

                    const chpBlkList = [];
                    const latestChp = 1;    //--- CHANGE latest chapter
                    for (var chp = 1; chp <= latestChp; chp++) {
                        var chpT = "chp"+chp;
                        chpBlkList.push(
                            <Row className="chapter-block">
                                <Col className="chapter chapter-num" sm="5">
                                    <div>{book[chpT][lang]}</div>
                                </Col>
                                <Col className="chapter chapter-title" sm="7">
                                    <Nav.Link href={ path + bookurl[bookName][chp]} className="px-0 p-0">
                                        <Row>
                                            <Col xs="8" className="pl-0">{book[bookName][chp][lang]}</Col>
                                        </Row>
                                    </Nav.Link>
                                </Col>
                            </Row>
                        );
                    }

                    return (
                        <>
                        <Layout>
                            <div id="bodyPage" className="body-page body-bg">
                                <main role="main" className="main">
                                <Container>
                                    <Row className="bookMenu">
                                        <Col xs={12} className="d-flex justify-content-end mt-2 pl-0">
                                            <div className="rciMenu"><Nav.Link href={"/"+lang} className="icon"><FontAwesomeIcon icon={faHome} /></Nav.Link></div>
                                        </Col>
                                    </Row>
                                    <div className="bookInfo">
                                        <Row>
                                            <Col xs={5} md={3}>
                                                <LocalImage name="arwS2_cover" attr={{className: "cover w-100", alt:`Cover`}}/>
                                            </Col>

                                            <Col xs={7} md={9} className="pl-0">
                                            <h3 className="title">{book[bookName].bookTitle[lang]}</h3>
                                            <h3 className="title">{book[bookName].bookSubTitle[lang]}</h3>
                                            {/* <h3>{ bookurl[bookName]['0'] }</h3> */}
                                            {/* <h3>{ book[bookName].bookSubTitle.arwS2['zh-cn'] }</h3> */}
                                            {/* <h3>{ book[bookName]['1']['en'] }</h3> */}
                                            {/* {messages[lang].labelCreator}    : {messages[lang].txtCreator}<br/> */}
                                            {/* {messages[lang].labelAuthor}     : {messages[lang].txtAuthor}<br/> */}

                                            {/* {{ 'en': 'Translator : Johnny Ko, Harriet Chung, Gigi Chang and Anna Holmwood', 'zh-hk': '', 'zh-cn': '' }[lang]} */}


                                                <div className="description d-none d-md-block">
                                                    <p>{book.arwS2.txtDescription[lang]}</p>
                                                    {/* <p className="text-center">
                                                    <a href={ path + bookurl[bookName][latestChp]} className="btn btn-lg px-5" >{messages[lang].TEXT181003}</a>
                                                    </p> */}
                                                </div>

                                            </Col>

                                            <Col xs={12} className="description d-block d-md-none">
                                                <p>{book.arwS2.txtDescription[lang]}</p>
                                                {/* <p className="text-center">
                                                <a href={ path + bookurl[bookName]["0"]} className="btn btn-lg px-5" >{messages[lang].TEXT181003}</a>
                                                </p> */}
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="bookIndex">
                                        <Row className="chapter-block">
                                            <Col className="title-hl">
                                                <Nav.Link href={ path + bookurl[bookName][0]}>{book["prologue"][lang]}</Nav.Link>
{/* <div className="no-transform">
Hey Commanders! 
<br/><br/>
The story here needs a bit of a retrofit: we'll be releasing the new version soon
</div> */}
                                            </Col>
                                        </Row>

                                        {/* <Row className="chapter-block">
                                            <Col className="chapter" sm="5">
                                                <div>{book["chp1"][lang]}</div>
                                            </Col>
                                            {//* // <Col className="session" sm="7">
                                            <Col className="chapter" sm="7">
                                                <Nav.Link href={ path + bookurl["1"]} className="px-0 p-0">
                                                    <Row>
                                                        {//* // <Col xs="5">{{'en':'Session'}[lang]} 1 {{'zh-hk':'節','zh-cn':'节'}[lang]}</Col>
                                                        <Col xs="8" className="pl-0">{book["1"][lang]}</Col>
                                                    </Row>
                                                </Nav.Link>
                                            </Col>
                                        </Row> */}

                                        {chpBlkList}

                                        {/* <Row className="chapter-block">
                                            <Col className="chapter" sm="5">
                                                {{ 'en': 'Chapter 2', 'zh-hk': '第2章', 'zh-cn': '第2章' }[lang]}
                                                <div>{book["2"][lang]}</div>
                                            </Col>
                                            <Col className="session" sm="7">
                                                <Nav.Link href={ path + bookurl["2.1"]}  className="px-0">
                                                    <Row>
                                                        <Col xs="5">{{'en':'Session'}[lang]} 2.1 {{'zh-hk':'節','zh-cn':'节'}[lang]}</Col>
                                                        <Col xs="7" className="pl-0">{book["2.1"][lang]}</Col>
                                                    </Row>
                                                </Nav.Link>
                                                <Nav.Link href={ path + bookurl["2.2"]}  className="px-0">
                                                    <Row>
                                                        <Col xs="5">{{'en':'Session'}[lang]} 2.2 {{'zh-hk':'節','zh-cn':'节'}[lang]}</Col>
                                                        <Col xs="7" className="pl-0">{book["2.2"][lang]}</Col>
                                                    </Row>
                                                </Nav.Link>
                                            </Col>
                                        </Row> */}

                                    </div>

                                </Container>

                                </main>
                            </div>
                        </Layout>
                        </>
                    )
                }}

            </NamespacesConsumer>
        )
    }
}

export default withI18next()(ARWS2BookIndexPage)

export const query = graphql`
    query getARWBookS2HomeLang($lng: String!) {
        locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
            ...LocaleFragment
        }
    }
`
